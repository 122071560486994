
  import { Component, Watch } from 'vue-property-decorator'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { Form } from '@/entities/public/Resource/metadata'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import { parseToNumber, sendNotification, updateNestedObject } from '@/utils/general'
  import { ItemType, SaleOrder, SaleOrderItem, Stock } from '@/entities/sales'
  import { plainToInstance } from 'class-transformer'
  import { Deal, DealType, Lead, Pipeline } from '@/entities/crm'
  import { Evaluation, Financing } from '@/entities/loans'
  import { Process, ProcessStatus } from '@/entities/settings'
  import InitialFields from '@/components/forms/fields/InitialFields.vue'
  import dayjs from 'dayjs'
  import { Debounce } from '@/utils/decorators'
  import { LeadView } from '@/components/forms/view/LeadView'

@Component({
  components: {
    InitialFields,
    FieldTitle,
    LinkedPerson,
    BaseForm,
    GAlert,
  },
  computed: {},
  methods: {},
})
  export default class ForwarderLeadForm extends LeadView {
  lead: Lead = plainToInstance(Lead, {})
  dealSale: Deal = plainToInstance(Deal, {})
  process: Process = plainToInstance(Process, {})
  multipleFinancials = []
  title = 'Lead'
  showDetail = false
  disableFinancing = false
  initialValue = 0
  vehicleAlert = {
    open: false,
    title: 'Debes Agregar un Vehiculo para crear un lead',
  }

  loaded = false
  forwarder = true

  declare $refs: {
    form: HTMLFormElement
    fieldClient: LinkedPerson
    fieldExecutive: LinkedPerson
    fieldReferred: LinkedPerson
  };

  formData = {
    channel: null,
    client: null,
    stock: null,
    discount: null,
    pie: null,
    intention: null,
    note: null,
    applicant: null,
    appraisal: null,
    executive: null,
    referred: null,
    financial: null,
  }

  metadata = {}
  metadataCollection = {
    lead: null,
    deal: null,
    financing: null,
  }

  fields = {
    financial: {
      properties: {},
    },
    channel: {
      properties: {},
      items: [],
      rules: [],
    },
    client: {
      properties: {
        properties: {
          itemText: 'formInfo',
        },
      },
      items: [],
      rules: [],
    },
    executive: {
      properties: {
        required: true,
        creatable: false,
        properties: {
          label: 'Ejecutivo',
          itemText: 'name',
          clearable: false,
          itemValue: 'id',
          placeholder: 'Busca...',
          hideSelected: true,
        },
      },
      items: [],
      rules: [],
    },
    applicant: {
      properties: {
        properties: {
          itemText: 'formInfo',
          itemValue: 'id',
        },
      },
      items: [],
      rules: [],
    },
    stock: {
      properties: {},
      items: [],
      rules: [],
    },
    discount: {
      properties: {},
    },
    pie: {
      properties: {},
    },
    appraisal: {
      properties: {
        label: 'Tasación',
        placeholder: 'Registra una tasación',
        appendOuterIcon: 'mdi-plus-circle-outline',
        readonly: true,
      },
    },
    intention: {
      properties: {},
      items: [],
    },
    note: {
      properties: {},
    },
    referred: {
      properties: {
        properties: {
          label: 'Cliente que refiere',
          rules: this.fieldRequired,
          itemText: 'formInfo',
          itemValue: 'id',
          clearable: true,
          returnObject: true,
        },
      },
      items: [],
    },
  }

  stock: Stock | null = null
  pipelines: Array<Pipeline> = []

  async mounted () {
    await this.setMetadata()
    const { uid, title } = this

    if (!isNaN(uid)) {
      await this.getLeadInfo(uid)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(this.metadataCollection.lead, title, Boolean(!isNaN(uid)))
    }

    await this.setTheBackup()
  }

  async getLeadInfo (id) {
    this.lead = await this.fetchLead(id)
    await this.setFormData(this.lead)
    await this.handleSale()
    await this.handlePurchase()
    this.setDetails()
  }

  async handleSale () {
    if (this.lead.sale?.id) {
      const dealSale = await this.fetchDealSale(this.lead.sale.id)
      this.lead.sale.saleOrders = dealSale?.saleOrders
      this.dealSale = dealSale
      await this.processOrder(dealSale)
    }
  }

  async processOrder (dealSale) {
    let order
    if (dealSale) {
      const { order: saleOrder } = dealSale
      order = saleOrder
    }
    const discount = order?.saleOrderItems.filter(saleOrderItem => saleOrderItem.isDiscount)
    this.applyDiscount(discount)
    this.applyFinancing(dealSale)
    const financing = order?.financings[0]
    this.formData.applicant = financing?.applicant

    if (this.formData?.applicant?.id) {
      await this.searchPerson({ input: this.formData.applicant.mainPhone, type: 'applicant' })
    }
  }

  applyDiscount (discount) {
    if (discount?.length && discount[0]?.amount) {
      this.formData.discount = Math.abs(discount[0]?.amount)
    }
  }

  applyFinancing (dealSale) {
    const isFinancing = Boolean(dealSale?.financial?.id)
    this.disableFinancing = isFinancing || Boolean(this.lead?.id)

    if (isFinancing) {
      const initial = dealSale?.financial?.initial
      const total = dealSale?.stock?.price?.amount - (this.formData.discount || 0)
      this.initialValue = (initial / total) * 100
    }
  }

  async handlePurchase () {
    if (this.lead.purchase?.id) {
      this.fields.appraisal.properties.appendOuterIcon = 'mdi-pencil'
    }
  }

  async setFormData ({ channel, sale, purchase, initialNote, client, executive }) {
    this.formData.channel = channel
    this.formData.stock = sale?.auto
    if (sale?.auto) {
      this.fields.stock.items = [sale.auto]
    }
    this.formData.appraisal = purchase?.auto?.name
    this.formData.intention = sale?.intention ?? purchase?.intention
    this.formData.note = initialNote
    this.stock = sale?.stock
    this.formData.client = client
    this.formData.executive = executive?.person
    this.formData.referred = sale?.referrer ?? purchase?.referrer

    if (this.formData?.referred?.id) {
      this.fields.referred.items = [this.formData?.referred]
    }

    if (this.formData?.client?.id) {
      this.fields.client.items = [this.formData?.client]
    }
  }

  async setProcess () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'financing' } },
    })
    this.process = process[0]
  }

  async setMetadata () {
    const { metadata } = this.getForm('Lead', 'lead')
    const { fields } = metadata as Form

    this.fields.channel.properties = fields.channel?.properties
    this.fields.financial.properties = fields.financial.properties
    Object.assign(this.fields.client.properties, fields.client?.properties)
    this.fields.intention.properties = fields.intention?.properties
    this.fields.note.properties = fields.note?.properties
    this.metadataCollection.lead = metadata
    this.fields.stock.properties = fields.auto?.properties
    this.fields.discount.properties = fields.amount?.properties

    const { metadata: financingMetadata } = this.getForm('Financing', 'financing')
    const { fields: financingFields } = financingMetadata as Form

    Object.assign(this.fields.applicant?.properties, financingFields.applicant?.properties)
    this.fields.pie.properties = financingFields.initial?.properties

    this.fields.intention.items = await this.fetchData({
      query: { name: 'find', model: 'Intention' },
    })

    this.fields.channel.items = await this.fetchData({
      query: { name: 'find', model: 'Channel' },
      filter: { ...fields.channel?.computed?.queries.items.where },
    })

    this.fields.stock.items = await this.fetchData({
      query: { name: 'find', model: 'Auto' },
      filter: { ...fields.auto?.computed?.queries.items.where._and[0] },
    })

    this.fields.executive.items = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: {
        _and: [
          { user: { roles: { rol: { slug: { _eq: 'staff' } } } } },
          { employee: { id_process_status: { _eq: this.employeeStatus.active?.[0].id } } },
        ],
      },
      limit: 100,
    })
    await this.setOtherData()
  }

  async setOtherData () {
    this.pipelines = await this.fetchData({
      query: { name: 'find', model: 'Pipeline' },
    })

    this.setDetails()
  }

  async setTheBackup () {
    const { backup } = this
    if (backup) {
      this.handleBackup(backup)
      this.handleAutoBackup(backup)
      this.handleFinancialBackup(backup)
      this.loaded = true
    } else {
      await this.handleRoute()
    }
  }

  handleFinancialBackup (backup) {
    if ('financialForm' in backup) {
      this.multipleFinancials.push(backup.financialForm)
    }
  }

  handleBackup (backup) {
    const fieldsToBackup = ['applicant', 'channel', 'client', 'intention', 'note', 'stock', 'executive', 'referred']
    const fieldsToReverseBackup = ['discount', 'pie']

    fieldsToBackup.forEach(field => {
      this.formData[field] = this.formData[field] ?? backup[field]
    })

    fieldsToReverseBackup.forEach(field => {
      this.formData[field] = backup[field] ?? this.formData[field]
    })
  }

  handleAutoBackup (backup) {
    if ('auto' in backup && backup.auto) {
      this.formData.appraisal = backup.auto.name
      this.fields.appraisal.properties.appendOuterIcon = backup.auto.name ? 'mdi-pencil' : 'mdi-plus-circle-outline'
    }
  }

  async handleRoute () {
    const { query: { stockId, channel } } = this.$route

    if (!this.formData?.stock) {
      await this.searchStockBy(stockId)
    }

    if (!this.formData?.channel) {
      this.formData.channel = channel ? this.fields.channel?.items.find(c => c.name === channel) : null
    }
  }

  async searchStockBy (id) {
    if (!id) return null

    const { metadataCollection: { lead } } = this
    if (!lead) return null
    const { fields } = lead

    const items = await this.fetchData({
      query: { name: 'find', model: 'Auto' },
      filter: { _and: [{ ...fields.auto.computed.queries.items.where._and[0] }, { id: { _eq: id.toString() } }] },
    })

    this.formData.stock = items[0]
    this.fields.stock.items = items
  }

  validateForm () {
    const client = this.$refs.fieldClient
    const referred = this.$refs.fieldReferred
    const note = this.formData.note?.length > 256

    if (this.formData.channel.name === 'referred' && (referred && !referred.$refs.form.validate())) {
      return true
    }

    if (!this.$refs.form.validate() || !client.$refs.form.validate() || note) {
      return true
    }
  }

  async send () {
    if (this.validateForm()) {
      return
    }

    this.loadingForm = true
    const { lead: { id } } = this

    if (!id) {
      await this.createLead(this, true)
    } else {
      await this.editLeadExecutive(this)
    }
    this.setBackup(null)
    await this.close()
  }

  get isMultipleFinancing () {
    const { process } = this

    return process?.config?.multipleFinancing
  }

  get isFinancingPending () {
    const { financings } = this

    const financing = financings?.filter(financing => financing?.applicant?.id).find(financing => financing.evaluation.status.isPending)
    return Boolean(financing)
  }

  get enableFinancing () {
    const { isMultipleFinancing, isFinancingPending } = this

    return !isMultipleFinancing && isFinancingPending
  }

  get isNotDeal () {
    const { purchase, sale } = this.lead

    return !(Boolean(sale?.auto) || Boolean(purchase?.auto))
  }

  get clientTitle (): string {
    const { fields } = this
    if (!fields) return ''

    const { client } = fields
    if (!client) return ''

    const { properties } = client as any
    if (properties?.properties?.hint) {
      const hint = properties?.properties?.hint
      delete properties?.properties?.hint
      return hint
    }

    return ''
  }

  get discount () {
    const { stock } = this

    return stock?.price?.bonus?.amount || stock.price.margin || 0
  }

  get disabledApplicant () {
    const { lead } = this
    return Boolean(lead.sale?.financial?.id)
  }

  get financings () {
    const { lead } = this

    const financingsCopy = [...(lead.sale?.order?.financings || [])]

    financingsCopy.sort((a, b) => {
      if (a.id == null) return -1
      if (b.id == null) return 1
      return b.id - a.id
    })

    return financingsCopy
  }

  get total () {
    const { formData: { discount }, stock, lead } = this

    const total = lead.sale?.stock?.price?.amount ? lead.sale?.stock?.price?.amount : stock?.currentPrice?.amount
    return (total ?? 0) - (Number(discount) || 0)
  }

  get maxPie () {
    const { total, metadataCollection: { lead } } = this

    if (!total) return 0

    const { fields: { config: { init: { value: { maxPie } } } } } = lead

    return (total - maxPie)
  }

  get bindClient () {
    const { fields: { client } } = this

    if (!client) return {}

    return { ...client.properties, items: client.items }
  }

  get bindExecutive () {
    const { fields: { executive } } = this
    if (!executive) return {}

    return { ...executive.properties, items: executive.items }
  }

  get bindReferred () {
    const { fields: { referred } } = this

    if (!referred) return null
    return { ...referred.properties, items: referred.items }
  }

  get isReferred () {
    const { formData: { channel } } = this

    return channel?.name === 'referred'
  }

  get initial () {
    const { formData } = this

    return Math.round(parseToNumber(formData.pie?.amount)) ?? 0
  }

  get backupAndPipeline () {
    const { backup, pipelines, formData: { appraisal } } = this

    return { backup, pipelines, appraisal }
  }

  @Debounce(500)
  async searchStock (input) {
    if (!input?.length || input?.length < 2) return null

    const { metadataCollection: { lead } } = this
    if (!lead) return null
    const { fields } = lead
    const query = updateNestedObject(fields.auto.computed.queries.items.where, '_eq', input)

    const items = await this.fetchData({
      query: { name: 'find', model: 'Auto' },
      filter: { ...query },
    })

    if (items.length) {
      this.fields.stock.items = items
    }
  }

  async searchPerson ({ input, type }) {
    if (!input?.length || input?.length < 2) return null

    const { metadataCollection: { lead } } = this
    if (!lead) return null
    const { fields } = lead
    const query = updateNestedObject(fields.client.computed.queries.items.where, '_eq', input, ['name_person_type'])

    const items = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { ...query },
    })

    if (type === 'client') {
      this.fields.client.items = items
    } else if (type === 'referred') {
      this.fields.referred.items = items
    } else {
      this.fields.applicant.items = items
    }
  }

  async goPerson (type) {
    const { backup, formData } = this

    if (!backup) {
      this.setBackup({ ...formData })
    } else {
      const back = {
        ...formData,
        appraisalForm: backup?.appraisalForm,
        auto: backup?.auto,
        financialForm: backup?.financialForm,
      }
      this.setBackup(back)
    }
    const idPerson = this.formData[type]?.id
    await this.$router.push({ name: 'generic-person-nested', params: { model: 'Lead', uid: null, id: idPerson } })
  }

  async openFinancial () {
    const { backup, formData, lead } = this
    if (!backup) {
      this.setBackup({ ...formData, lead })
    } else {
      const back = {
        ...formData,
        lead,
        appraisalForm: backup?.appraisalForm,
        auto: backup?.auto,
        financialForm: null,
      }
      this.setBackup(back)
    }

    await this.$router.push({
      name: 'generic-financial',
      params: {
        model: 'Lead',
        uid: lead.id ? lead.id?.toString() : 'create',
        id: 'create',
      },
    })
  }

  async openAppraisal () {
    const { backup, formData, lead } = this
    if (!backup) {
      this.setBackup({ ...formData })
    } else {
      const back = {
        ...formData,
        appraisalForm: backup?.appraisalForm,
        auto: backup?.auto,
        financialForm: backup?.financialForm,
      }
      this.setBackup(back)
    }
    await this.$router.push({
      name: 'generic-appraisal',
      params: {
        model: 'Lead',
        uid: lead.id ? lead.id?.toString() : 'create',
        id: this.lead.purchase?.appraisal?.id?.toString() ?? 'create',
      },
    })
  }

  @Watch('formData.stock', { immediate: true, deep: true })
  async onStockChange (auto) {
    if (auto) {
      this.disableSend = false

      const stock = await this.fetchData({
        query: { name: 'find', model: 'Stock' },
        filter: { _and: [{ stock_detail: { id_auto: { _eq: auto.id } } }, { status: { status: { name: { _neq: 'sold' } } } }] },
      })
      const stockDetail = await this.fetchData({
        query: { name: 'find', model: 'StockViewDetails' },
        filter: { id_stock: { _eq: stock[0].id } },
      })
      this.stock = stock[0]
      this.stock.viewDetails = stockDetail[0]
      this.stock.transferCost = await this.calculateTransfer(this.stock?.id)

      await this.setDealSale(this.formData)
    }
    if (this.lead?.sale?.auto && !auto) {
      this.lead.sale.auto = null
      this.formData.pie = null
      this.formData.discount = null
      await this.setDealSale(this.formData)
    }
    this.cleanFormFinancialData()
    await this.checkExistingLead()

    this.setDetails()
  }

  async checkExistingLead () {
    const { formData, lead } = this

    if (!formData?.client || !formData?.stock || Boolean(lead?.sale?.id)) return

    const resp = await this.getOpenLead({ autoId: formData.stock.id, clientId: formData.client.id })
    const leadInfo = plainToInstance(Lead, resp[0])

    if (leadInfo) {
      sendNotification(`Este cliente ya tiene un negocio en progreso con ese auto, lead: Nº ${leadInfo.id} con el ejecutivo ${leadInfo.executive.person.firstName} ${leadInfo.executive.person.surname}`, 'error')
      this.formData.stock = null
      this.disableSend = true
    }
  }

  @Debounce(500)
  cleanFormFinancialData () {
    const { backup, formData } = this
    if (!backup) return

    const stock = 'stock' in backup && backup?.stock

    if (!formData?.stock && !stock) return

    if (parseToNumber(stock?.id) !== parseToNumber(formData?.stock)) {
      if (backup?.financialForm) {
        this.backup.financialForm = null
      }
      if (this.lead?.sale?.order?.financings) {
        this.lead.sale.order.financings = []
      }
    }
  }

  @Watch('initial', { immediate: true, deep: true })
  onInitialChange () {
    if (this.formData.stock) {
      this.setDealSale(this.formData)
      this.setDetails()
    }

    this.fields.applicant.items = [this.formData.client]
  }

  @Watch('formData.client', { immediate: true, deep: true })
  async onClientChange (client) {
    this.lead.client = client
    await this.checkExistingLead()
  }

  @Watch('formData.applicant', { immediate: true, deep: true })
  onChangeApplicant (applicant) {
    if (applicant && !applicant?.uid) {
      this.fields.applicant.properties.properties['error-messages'] = 'Falta rut del solicitante'
    } else if (applicant?.uid) {
      this.fields.applicant.properties.properties['error-messages'] = ''
    }
  }

  @Watch('formData.discount', { immediate: true })
  onDiscountChange (discount) {
    if (discount) {
      this.setDealSale(this.formData)
      this.setDetails()
    }
  }

  @Watch('backupAndPipeline', { immediate: true, deep: true })
  @Debounce(500)
  async onBackupUpdate (val) {
    if (val.backup?.auto) {
      await this.setDealPurchase(val.backup.auto, val.backup.appraisalForm)
    }

    if (this.lead?.purchase?.auto && !val.appraisal) {
      this.lead.purchase.auto = null
      this.setDetails()
    }
  }

  setDetails () {
    const { metadataCollection, lead, pipelines } = this
    this.lead.pipeline = pipelines.find(pipeline => pipeline.id === this.lead.computedPipeline)
    this.metadata = {
      data: lead,
      metadata: metadataCollection.lead,
    }
    const { purchase, sale } = lead

    this.showDetail = (Boolean(sale?.auto) || Boolean(purchase?.auto))
  }

  async setDealPurchase (auto, data) {
    const offer1 = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: data.companies.offer1.id } },
    })
    const offer2 = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: data.companies.offer2.id } },
    })
    let components
    let componentValue

    if (data.owner) {
      components = await this.fetchData({
        query: { name: 'find', model: 'Component' },
        filter: { id: { _eq: this.owner[0].id } },
      })
      componentValue = components[0].values.find(value => value.id === data.owner)
    }

    this.lead.purchase = plainToInstance(Deal, {
      type: { name: 'purchase' },
      appraisals: [{
        externalOffers: [
          { amount: data.offer1, company: { alias: offer1.alias } },
          { amount: data.offer2, company: { alias: offer2.alias } },
        ],
        responses: [{
          amount: data.price,
          comment: data.comment,
          createdAt: dayjs().add(4, 'hours').format('MM/DD/YYYY HH:MM'),
          type: { name: 'expected_offer', description: 'Precio esperado' },
        }],
      }],
      autoAttributes: [
        { component: { name: 'Kilometraje', favorite: true }, value: data.kms },
        { component: { name: 'Nº de dueños', favorite: true }, componentValue: { value: componentValue?.value } },
      ],
    })

    this.lead.purchase.auto = auto
    this.lead.purchase.auto.maintenances = data?.maintenance
    this.setDetails()
  }

  async setDealSale (data) {
    const { total, initial, lead, stock, backup } = this
    if (lead?.id && lead?.sale?.id && lead.sale?.financial?.id) {
      this.lead.sale.stock = stock
      return null
    }

    this.lead.sale = plainToInstance(Deal, { type: plainToInstance(DealType, { name: 'sale' }) })
    const financing = plainToInstance(Financing, {})
    const saleOrder = plainToInstance(SaleOrder, { id: lead?.sale?.order?.id })
    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { evaluations: { status: { status: { name: { _eq: 'pending' } } } } },
    })
    const evaluation1 = plainToInstance(Evaluation, { agreement: { id: 1 } })
    const evaluation2 = plainToInstance(Evaluation, { agreement: { id: 2 } })

    evaluation1.status = status[0]
    evaluation2.status = status[0]
    financing.initial = initial || backup?.financialForm?.pie?.amount
    financing.warrantyAmount = total
    financing.applicant = data.applicant || backup?.financialForm?.applicant
    financing.evaluations = [evaluation1, evaluation2]

    saleOrder.financings = [financing]
    saleOrder.status = plainToInstance(ProcessStatus, { id: this.statusSaleOrder?.[0]?.id })

    saleOrder.saleOrderItems = lead?.sale?.order?.saleOrderItems || [
      plainToInstance(SaleOrderItem, { amount: data.stock?.lastPrice, type: plainToInstance(ItemType, { id: 1 }) }),
      plainToInstance(SaleOrderItem, {
        amount: data.discount,
        type: plainToInstance(ItemType, { id: 2, name: 'discount' }),
      }),
    ]

    this.lead.sale.auto = data.stock
    this.lead.sale.intention = data.intention
    this.lead.sale.channel = data.channel
    this.lead.sale.saleOrders = [saleOrder]
    this.lead.sale.stock = stock
  }

  get isListPrice () {
    const { stock } = this

    return Boolean(stock?.price?.amount)
  }
  }
